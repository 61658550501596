var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view view--elitepage view--mt-0" },
    [
      _vm.mailingListPage
        ? [
            _c("section", { staticClass: "section section--fullScreen" }, [
              _c(
                "div",
                { staticClass: "container h-textCenter" },
                [
                  _vm.mailingListPage.thankyou_image_url
                    ? _c("img", {
                        staticClass: "emailImg",
                        attrs: { src: _vm.mailingListPage.thankyou_image_url },
                      })
                    : _c("icon", {
                        staticClass: "emailIcon",
                        attrs: { name: "paper-plane" },
                      }),
                  _c("h1", { staticClass: "h1" }, [
                    _vm._v(_vm._s(_vm.mailingListPage.thankyou_text_title)),
                  ]),
                  _c("p", { staticClass: "p textSizeMd" }, [
                    _vm._v(_vm._s(_vm.mailingListPage.thankyou_text_content)),
                  ]),
                  _vm.mailingListPage.thankyou_btn_text &&
                  _vm.mailingListPage.thankyou_btn_link
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn--primary",
                          attrs: {
                            href: _vm.mailingListPage.thankyou_btn_link,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.mailingListPage.thankyou_btn_text))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }